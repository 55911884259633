<template>
  <ul class="article_map">
    <li class="item" v-for="(item, index) in siteList" :key="item.id">
      <div class="row">
        <i class="el-icon-time"></i>
        <span class="time">{{ item.createdAt }}</span>
      </div>
      <div class="item_content">
        <div class="line" v-if="index !== siteList.length - 1"></div>
        <div class="article_title">
          <a @click="toDetail(item.id)">{{ item.title }}</a>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import article from "@/api/article";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      siteList: [],
    };
  },
  created() {
    this.getArticle();
  },
  methods: {
    ...mapActions(["toDetail"]),
    async getArticle() {
      const res = await article.getArticle();
      this.siteList = res.data;
    },
  },
};
</script>

<style lang="less">
.article_map {
  background-color: #f5f5f5;
  border-radius: 5px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue,
    MIcrosoft YaHei, sans-serif !important;
  padding: 40px 20px 30px;
  .item {
    position: relative;
    .row {
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: wrap;

      .time {
        margin-left: 5px;
      }
    }
    .item_content {
        position: relative;
        left: 6px;
        padding: 5px 0;
      .article_title {
        margin-left: 10px;
        line-height: 30px;
        background-color: #cce8cf;
        border-radius: 5px;
        padding: 2px 10px;
        color: #727677;
        position: relative;
        display: inline-block;
        left: 5px;

        &::before {
          content: "";
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translateY(-50%);
          border: 10px solid transparent;
          border-right-color: #cce8cf;
        }

        a:hover {
          color: #58a6e7;
          text-decoration: underline;
        }
      }
      .line {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-left: 6px;
        border-left: 2px solid #308405;
        top: 0;
        left: -6px;
      }
    }
  }
}
</style>